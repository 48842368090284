var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{ref:"popup",attrs:{"name":_vm.mid,"variant":"subscription","hideCloseButton":true,"disableBodyScroll":true}},[_c('section',[_c('transaction-card',{attrs:{"amount":_vm.transaction.amount,"currencySymbol":_vm.getCurrencySymbol}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.paymentFor'),"value":_vm.transaction.payment_for,"icon":"fas fa-credit-card mr-3 green","value_class":"value_icon"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.paymentMethod'),"value":_vm.transaction.payment_method,"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.payerName'),"value":"--","value_class":"normal_value"}},[(_vm.transaction.user)?_c('router-link',{staticClass:"font-bold text-blue-600",attrs:{"to":{
            name: _vm.getViewName(_vm.transaction.user.role_name),
            params: { id: _vm.transaction.user.id },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.transaction.user.full_name)+" ")]):_vm._e()],1),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.payerContact'),"value":_vm.transaction.user ? _vm.transaction.user.phone_number : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.transactionTime'),"value":_vm.getFormattedDateTime(_vm.transaction.date, 'D MMM, YYYY hh:mm:ss a'),"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.transactionID'),"value":_vm.transaction.id,"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.taxID'),"value":_vm.transaction.tax_id ? _vm.transaction.tax_id : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.refundedAmount'),"value":_vm.transaction.is_refunded
            ? _vm.getFormattedPrice(_vm.transaction.amount)
            : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.refundStatus'),"value":_vm.transaction.refund_status && _vm.transaction.refund_status !== ''
            ? _vm.transaction.refund_status
            : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.clientIP'),"value":_vm.transaction.client_ip_address ? _vm.transaction.client_ip_address : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.fingerprint'),"value":_vm.transaction.payment_method_details &&
          _vm.transaction.payment_method_details.fingerprint
            ? _vm.transaction.payment_method_details.fingerprint
            : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.cardOriginCountry'),"value":_vm.transaction.payment_method_details &&
          _vm.transaction.payment_method_details.card_origin_country
            ? _vm.transaction.payment_method_details.card_origin_country
            : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.cardExpireDate'),"value":_vm.transaction.payment_method_details &&
          _vm.transaction.payment_method_details.expiry
            ? _vm.getFormattedDateTime(
                _vm.transaction.payment_method_details.expiry,
                'D MMM, YYYY'
              )
            : '--',"value_class":"normal_value"}}),_c('transaction-list',{attrs:{"title":_vm.$t('components.transactionPopup.cardExtraDetails'),"value":_vm.transaction.payment_method_details &&
          _vm.transaction.payment_method_details.extra_detail
            ? _vm.transaction.payment_method_details.extra_detail
            : '--',"value_class":"normal_value"}}),_c('cost-message-box',{attrs:{"message":_vm.$t('components.transactionPopup.transactionAmount', {
            currencySymbol: _vm.getCurrencySymbol,
            transactionAmount: _vm.transaction.amount,
          }),"width":"width-50"}})],1),_c('div',{staticClass:"flex items-center justify-end px-4 py-4 mt-8"},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$refs.popup.hide()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.close'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }